<template>
  <div>
    <v-card
      outlined
      class="item-person"
    >
      <div class="header">
        <v-list-item
          class="item"
          :class="{ 'pr-0': $slots.append }"
        >
          <v-list-item-avatar class="avatar">
            <div
              v-if="rank"
              class="font-weight-bold"
              style="width: 100%"
            >
              {{ rank }}
            </div>
            <v-icon
              v-else
              color="black"
              >mdi-{{ icon }}</v-icon
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <div
              :class="nameSize"
              class="font-weight-medium"
            >
              {{ entity.name }}
            </div>
            <slot
              name="details"
              :entity="entity"
            />
            <div
              v-if="entity.type === 'charity'"
              class="text-xs font-weight-medium opacity-70"
            >
              Charity
            </div>
            <div
              v-if="type === 'professional'"
              class="text-xs font-weight-medium opacity-70"
            >
              Professional Executor
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="entity.type === 'person'">
            <v-btn
              icon
              color="black"
              class="bg-grey-30 radius-8"
              @click="openEditDrawer(true)"
              ><v-icon>mdi-pencil-outline</v-icon></v-btn
            >
          </v-list-item-action>
          <v-list-item-action
            v-if="$listeners.remove"
            class="ml-2"
          >
            <v-btn
              icon
              color="black"
              class="bg-grey-30 radius-8"
              @click="$emit('remove')"
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-list-item-action>
          <slot name="append" />
        </v-list-item>
      </div>
      <div class="content">
        <slot />
      </div>
    </v-card>
    <drawer
      title="Edit Person"
      :open.sync="openEdit"
    >
      <div class="pa-4">
        <slot name="form">
          <form-person
            v-if="openEdit"
            :id="id"
            @close="openEdit = false"
            @next="openEdit = false"
            :editable="editable"
            :recommended="recommended"
            :is-adult="isAdult"
          />
        </slot>
      </div>
    </drawer>
  </div>
</template>

<script>
import FormPerson from '../form/Person'
import Drawer from './Drawer'
export default {
  name: 'ItemPerson',
  components: { FormPerson, Drawer },
  props: {
    id: {
      default: null,
    },
    type: {
      default: 'person',
    },
    rank: {
      default: null,
    },
    nameSize: {
      default: 'text-lg',
    },
    editable: {},
    isAdult: {
      default: false,
    },
    recommended: {},
  },
  data: () => ({
    openEdit: false,
  }),
  computed: {
    entity() {
      if (this.type === 'person') {
        return this.id ? this.$store.getters['account/entity'](this.id) : {}
      }
      if (this.type === 'professional') {
        return this.id
          ? this.$store.getters['account/lists_group_option'](
              'professionalExecutors',
              this.id
            )
          : {}
      }

      return {}
    },
    icon() {
      return {
        person: 'account-outline',
        charity: 'charity',
        company: 'domain',
      }[this.entity.type]
    },
  },

  methods: {
    openEditDrawer(status) {
      this.openEdit = status
      this.$emit('edit')
    },
  },
}
</script>

<style lang="scss">
.item-person {
  .header {
    .item {
      min-height: 64px;
    }
    border-radius: 0px !important;
  }
  .avatar {
    background: rgba(var(--primary-rgb), 0.15);
  }
}
</style>
