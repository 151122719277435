<template>
  <v-window
    touchless
    v-model="window"
  >
    <v-window-item value="select">
      <div class="pa-4">
        Click on the name of the person you want to select or add a new person.
      </div>
      <div class="divider" />
      <select-list
        v-if="optionsPeople.length"
        :options="optionsPeople"
        @select="checkMandatoryFields"
      />
      <div
        v-else
        class="border-divider px-4"
      >
        <div class="text-no-result">
          No options to select, click the <strong>Add New</strong> button.
        </div>
      </div>
      <div class="text-center pa-4">
        <v-btn
          style="min-width: 50%"
          text
          large
          class="bg-grey-30 radius-4"
          @click="addNew"
          ><v-icon
            left
            size="24"
            color="primary"
            >mdi-plus-circle</v-icon
          >Add new</v-btn
        >
      </div>
    </v-window-item>
    <v-window-item value="add">
      <div class="pa-4">
        <form-person
          v-if="window === 'add'"
          @close="window = 'select'"
          close-label="Back"
          @next="selected"
          :id="selectedId"
          :excluded="exclude"
          :editable="editable"
          :recommended="recommended"
          :is-adult="isAdult"
        />
      </div>
    </v-window-item>
  </v-window>
</template>

<script>
import { isEmpty } from 'lodash'
import { mapGetters } from 'vuex'

import FormPerson from '../form/Person'
import SelectList from '../ui/SelectList'
export default {
  name: 'DrawerPersonSelect',
  components: { SelectList, FormPerson },
  props: {
    title: {
      default: 'Select',
    },
    exclude: {
      default: () => [],
    },
    editable: {},
    mandatory: {},
    recommended: {},
    isAdult: {
      default: false,
    },
  },
  data: () => ({
    selectedId: null,
    window: 'select',
  }),
  methods: {
    addNew() {
      this.selectedId = null
      this.window = 'add'
    },
    checkMandatoryFields(id) {
      const requiredFields = []
      if (!isEmpty(this.mandatory)) {
        const person = this.optionsPeople.find((x) => x.value === id)
        this.mandatory.forEach((element) => {
          if (element === 'dob') {
            if (
              !person.entity.dob ||
              person.entity.dob.year?.length !== 4 ||
              person.entity.dob.month?.length !== 2 ||
              person.entity.dob.day?.length !== 2
            ) {
              requiredFields.push(element)
            }
          } else if (
            person.entity &&
            (!person.entity[element]?.length ||
              person.entity[element]?.length === 0)
          )
            requiredFields.push(element)
        })

        if (requiredFields.length > 0) {
          this.selectedId = id
          this.window = 'add'
          return
        }
      }
      this.$emit('select', id)
    },
    selected(id) {
      this.$emit('select', id)
    },
  },
  computed: {
    ...mapGetters('account', ['entities_people']),
    optionsPeople() {
      return this.entities_people
        .filter((o) => {
          return !this.exclude.includes(o.id)
        })
        .map((o) => {
          return {
            value: o.id,
            label: o.name,
            entity: o,
          }
        })
        .slice()
        .sort((a, b) => {
          const labelA = a.label.toUpperCase()
          const labelB = b.label.toUpperCase()
          return labelA < labelB ? -1 : labelA > labelB ? 1 : 0
        })
    },
  },
}
</script>

<style lang="scss"></style>
